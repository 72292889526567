import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import IntegrationJira from '../components/PersonalIntegrations/jiraPage'
import { Helmet } from "react-helmet";

// const content = `Simplify workflow with DueFocus Integration. Track time in Jira, the best software development tool for agile teams. `
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const Jira = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>Jira Integration with DueFocus Time Tracking Software</title>
      <meta name="description" content={content} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.duefocus.com/time-tracking-jira/" />
    </Helmet>
    <Header />
    <IntegrationJira />
    <Footer />
  </>
)

export default Jira